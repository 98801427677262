.toolbar {
  position: sticky;
  background-color: black;
  color:white;
  height: 90px;
  top: 0;
  left: 0;
}

.toolbar_nav {
  display: flex;
  align-items: center;
  height: 100%;
  padding: .5rem 1rem;
}

.toolbar_logo {
  color:white;
  background-color: black;
  height: 80px;
  width:auto;
  margin: 0 1rem .5rem 0;
}

.spacer {
  flex: 1;
}

.toolbar_nav_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.toolbar_nav_items li {
  padding: 0 0.5rem;
}

.toolbar_nav_items a {
  color:white;
  text-decoration: none;
  font-family: "Oswald";
}

.toolbar_nav_items a:hover,
.toolbar_nav_items a:active {
  color: rgb(80, 147, 106);
}

@media (max-width: 768px) {
  .toolbar_nav_items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar_toggle_btn {
    display: none;
  }

}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');


.homepage-container {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-title {
  margin-top: 50px;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
}

.homepage-details {
  font-family: "Oswald";
  font-weight: 200;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 0 15px 70px 15px;
  text-align: center;
}

.valve-pic {
  height: auto;
  width: 100vw;
}

.discount-section {
  width: 100%;
  font-family: "Lato";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.discount {
  width: 90%;
  font-family: "Oswald";
  margin: 60px 60px;
  text-align: center;
  font-size: x-large;
  letter-spacing: 1px;
}

@media (min-width: 769px) {

  .homepage-details {
    width:70%;
    text-align: center;
    margin: 0 0 80px 0;
  }

  .valve-pic-container {
    display: flex;
    justify-content: center;
  }

  .valve-pic {
    height: auto;
    width: 80%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
}
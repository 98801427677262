.toggle_btn {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.toggle_btn:hover {
  color: rgb(80, 147, 106);
}

.menu_icon {
  height:2rem;
  width: auto;
}
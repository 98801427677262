.services-page-container {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-page-title {
  margin: 40px 0 40px 0;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
}

.services-pic-container {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background-color: rgb(199, 218, 208); 
  padding: 20px 0 20px 0;
}

.services-pic {
  height: auto;
  width: 90%;
}

.services-focus-title-container {
 width: 90%;
 text-align: left;
 margin-left: 10%;
}

.services-focus-title {
  font-family: "Oswald";
  letter-spacing: 1px;
  font-weight: 200;
}

.services-line {
  width: 90%;
  height: 2px;
  background-color: rgb(80, 147, 106);
  margin: 0 0 20px 5%;
}

.services-focus-list {
 margin: 15px 0 15px 0;
}

.ul-services {
  list-style: none;
}

.ul-services li::before {
  content: "\2713\0020";
  color:rgb(80, 147, 106);
}

.services-focus-list li {
  font-family: "Oswald";
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  margin-top: 8px;
}

@media (min-width: 769px) {
  
  .services-pic {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;;
    height:auto;
    width: 50%;
  }

  .services-focus-title-container {
    width: 100%;
    text-align: center;
    margin-left: 0%;
   }

   .services-focus-list {
    width: 100%;
    display: flex;
    justify-content: center;
   }

}
.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.cert-title {
  margin: 40px 0 40px 0;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  font-size: xx-large;
  font-weight: 200;
}

.cert-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cert {
  height: 50%;
  width:50%;
  margin-bottom: 10%;
}

.footer-everly-logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(234, 231, 231);
}


.footer-logo {
  color:black;
  background-color: rgb(234, 231, 231);
  height: auto;
  width: 150px;
  padding: 5%;
}

.footer-info-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(234, 231, 231);
  width: 100%;
  font-family: "Oswald";
  padding-bottom: 20px;
}

.footer-info-icon {
  margin-right: 5px;
}

.footer-phone-container, 
.footer-location-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-hours-container {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald";
  padding-bottom: 50px;
}

.hours-title {
  margin: 40px 0 0 0;
}

.footer-links-social-container {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0 20px 0;
}

.footer-links-container {
  background-color: black;
}

.footer-links-container ul {
  list-style: none;
  padding-inline-start: unset;
}

.footer-links-container li {
  margin-bottom: 8%;
}

.footer-links-container a {
  color:white;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Oswald";
}

.footer-links-container a:hover,
.footer-links-container a:active {
  color: rgb(80, 147, 106);
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.social-title {
  color: white;
  font-family: "Oswald";
  font-size: 1.2rem;
}

.social-link-container {
  display: flex;
  flex-direction: row;
  width:50%;
  justify-content: space-evenly;
}

.social-icon {
  color:white;
  height: 20px;
  width:20px;
}

.social-icon:visited {
  color:white;
}

.social-icon:hover {
  color:rgb(80, 147, 106);
}

.footer-copyright-container {
  width: 100%;
  background-color: black;
  color: white;
  font-family: "Oswald";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
}

.spacer-footer {
  margin-right: 3px;
}

.portfolio {
  text-decoration: none;
  color:gray;
}

.portfolio:visited {
  color:gray;
}

.portfolio:hover {
  color:rgb(80, 147, 106);
}

@media (min-width: 500px) {

  .footer-logo {
    height: auto;
    width: 25%;
  }

  .cert {
    height: 30%;
    width:30%;
  }

}

@media (min-width: 769px) {


  .cert-container {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .cert {
    height: 15%;
    width:15%;
  }
  
  .footer-logo {
    height: auto;
    width:25%;
  }

  .footer-hours-container {
    width: 100%;
    background-color: rgb(234, 231, 231);
    color: black;
  }
  
  .hours-title {
    color: black;
  }

  .hours-details {
    color:black;
  }

}

@media (min-width: 1200px) {
  .footer-logo {
    height: auto;
    width: 25%;
  }
}
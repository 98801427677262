.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: rgb(80, 147, 106);
  padding: 5% 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 80%;
  height: auto;
}

.contact-message-title {
  margin: 0 0 20px 0;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  font-weight: 200;
  font-size: x-large;
  color: white;
}

label {
  font-family: "Oswald";
  letter-spacing: 1px;
  font-weight: 200;
}

input {
  margin-bottom: .5rem;
  font-family: "Oswald";
  letter-spacing: 1px;
  font-weight: 200;
  height: 30px;
  border: 2px solid black;
  border-radius: 3px;
}

textarea {
  margin-bottom: .5rem;
  font-family: "Oswald";
  letter-spacing: 1px;
  font-weight: 200;
  height: 60px;
  border: 2px solid black;
  border-radius: 3px;
}

.form-btn {
  color:white;
  background-color: black;
  font-family: "Oswald";
  letter-spacing: 1px;
  font-weight: 400;
  height: 30px;
  border: 2px solid black;
  border-radius: 3px;
}

.contact-message {
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  font-size: x-large;
}

@media (min-width: 500px) {
  .form-container {
    width: 50%;
  }
}

@media (min-width: 769px) {
  
  .form-container {
    width: 50%;
  }

}

@media (min-width: 1200px) {
  .form-container {
    width: 35%;
    padding: 5% 0;
  }
}


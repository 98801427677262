@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

.phone-banner-container {
  background-color: rgb(80, 147, 106);
  padding: 2px 5px 2px 5px;
}

.phone-banner-link {
  background-color: black;
  margin: 10px;
  display: flex;
  justify-content: center;
  font-family: "Oswald";
  line-height: 30px;
  letter-spacing: 1px;
  align-items: center;
  text-decoration: none;
  color: white;
}

.phone-icon {
  margin-right: 5px;
  font-size: 1.5rem;
}

.phone-text {
  font-size: 1.5rem;
}

.phone-banner-link:hover {
  color: rgb(80, 147, 106);
}

.phone-banner-link:visited {
  color:white;
}


@media only screen and (min-width: 769px) {
  
  .phone-banner-container {
    padding: unset;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  
  .phone-banner-link {
    background-color:rgb(80, 147, 106);
    justify-content: right;
    margin: 0 20px 0 0;
    width: fit-content;
  }

  .phone-icon {
    font-size: 1.5rem;
  }
  
  .phone-text {
    font-size: 1.5rem;
  }

  .phone-banner-link:hover {
    color: black;
  }
}

.contact-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-page-title {
  margin: 40px 0 40px 0;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
}

.contact-pic-container {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.contact-pic {
  height: auto;
  width: 100%;
}

.contact-paragraph {
  text-align: center;
  font-family: "Oswald";
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 5% 10%;
}

.contact-line {
  width: 90%;
  height: 2px;
  background-color: rgb(80, 147, 106);
  margin: 0 5% 10% 5%;
}

.get-in-touch {
  text-align: center;
  font-family: "Oswald";
  letter-spacing: 1px;
  margin-bottom: 10%;
  color: rgb(80, 147, 106);
}

.contact-email-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.contact-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 10px;
  color: rgb(80, 147, 106);
}

.email-link {
  font-family: "Oswald";
  font-weight: 200;
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 1rem;
  color:black;
  text-decoration: none;
}

.email-link:hover, .email-link:visited {
  color:rgb(80, 147, 106);
}

.contact-phone-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.contact-phone-number {
  font-family: "Oswald";
  font-weight: 200;
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
  color:black;
}

.contact-phone-number:hover, .contact-phone-number:visited {
  color:rgb(80, 147, 106);
}

@media (min-width: 769px) {
  
  .contact-pic {
    border-radius: 15px;
    height:auto;
    width: 50%;
  }

  .contact-paragraph {
    width: 60%;
    font-size: large;
  }

  .contact-line {
    margin: 0 5% 5% 5%;
  }

  .get-in-touch {
    margin: 0 0 3% 0;
  }
  
  .contact-email-icon-container {
    margin-bottom: 4%;
  }

}
.side_drawer {
  height: 100%;
  background-color: black;
  color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side_drawer.open {
  transform: translateX(0);
}

.side_drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side_drawer li {
  margin: 0.5rem 0;
}

.side_drawer a {
  color: rgb(80, 147, 106);
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Oswald";
}

.side_drawer a:hover,
.side_drawer a:active {
  color:white;
}

@media (min-width: 769px) {
  .side_drawer {
    display: none;
  }
}
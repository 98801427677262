.about-page-container {
 width:100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.about-page-title {
  margin: 40px 0 40px 0;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
}

.handshake-pic-container {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.handshake-pic {
  height: auto;
  width: 100%;
}

.about-page-details-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-page-details-title {
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  margin: 0 20px 0 20px;
  width: 75%;
}

.about-page-line {
  width: 90%;
  height: 2px;
  background-color: rgb(80, 147, 106);
  margin-top: 5px;
}

.about-page-details {
  text-align: center;
  font-family: "Oswald";
  font-weight: 200;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 20px 20px 40px 20px;
}

.about-why-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(80, 147, 106);
  padding-top: 20px;
}

.about-why-title {
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  margin: 0 20px 0 20px;
  width: 75%;
}

.about-why-details-container {
  background-color: rgb(80, 147, 106);
}

.about-why-details {
  text-align: center;
  font-family: "Oswald";
  font-weight: 200;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 20px 20px 40px 20px;
}


@media (min-width: 769px) {
  
  .handshake-pic {
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;;
    height:auto;
    width: 50%;  
}

  .about-page-details-title{
    margin-top: 30px;
  }

  .about-page-details-container {
    width: 70%;
    margin: 10px 0 30px 15%;
  }    
}
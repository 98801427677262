@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');


.home-banner {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.tagline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tagline {
  color: white;
  font-family: "Oswald";
  letter-spacing: 1px;
  text-align: center;
  font-size: xx-large;
}

.tagline-btn {
  background-color: rgb(80, 147, 106);
  border: none;
  color: black;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 1;
  transition: .3s;
  font-family: "Oswald";
}

.tagline-btn:hover {
  opacity: .8;
  background-color: white;
}

@media (min-width: 769px) {
  .home-banner {
    height: 600px;
  }
}

@media (min-width: 900px) {
  .home-banner {
    height: 550px;
    width: 100%;
  }

  .tagline {
    font-size: 2.5rem;
  }
}

@media (min-width: 1100px) {
  .home-banner {
    height: 750px;
  }
}